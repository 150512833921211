import React from 'react';
import clsx from 'clsx';
import * as styles from './InternalHash.module.css';

export default function InternalHash({ className, hash, text=null, onClick }) {
  return (
    <a
      className={ clsx(styles.internalHash, className) }
      onClick={ onClick }
      href={`#${ hash }`}
      target='_self'>
        {
          text ? text : hash
        }
    </a>
  );
}